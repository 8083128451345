.header-width {
  width: 100%;
  max-width: 1200px;
}
.content-width {
  max-width: 1200px;
  width: 100%;
}
.mainblock-height{
  height: 80svh;
}


@media screen and (max-width: 900px) {
  .header-width {
    padding-left: 10px;
    padding-right: 10px ;
  }
  .content-width{
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  .w-45{
    width: 100%;
  }
}

@media screen and (max-width: 320px) {

  .mainblock-height{
    height: 100svh;
  }
  .w-45{
    width: 100%;
  }  
}

@media screen and (max-width: 720px) {.mainblock-height{
  height: 90svh;
}
}

.phone-block {
  border-radius: 24px;
  box-shadow: 0px 10px 21px 0px rgba(73, 70, 65, 0.10), 0px 39px 39px 0px rgba(73, 70, 65, 0.09), 0px 87px 52px 0px rgba(73, 70, 65, 0.05), 0px 154px 62px 0px rgba(73, 70, 65, 0.01);
}

.shadowblock {
  box-shadow: 0px 10px 21px 0px rgba(73, 70, 65, 0.10), 0px 39px 39px 0px rgba(73, 70, 65, 0.09), 0px 87px 52px 0px rgba(73, 70, 65, 0.05), 0px 154px 62px 0px rgba(73, 70, 65, 0.01);
}
.shadowblockSmall {
  box-shadow: 0px 5px 10px 0px rgba(73, 70, 65, 0.10), 0px 20px 20px 0px rgba(73, 70, 65, 0.09), 0px 40px 32px 0px rgba(73, 70, 65, 0.05), 0px 60px 30px 0px rgba(73, 70, 65, 0.01);
}
.imageblock {
  box-shadow: inset 0px 0px 0px 1px rgba(73, 70, 65, 0.10);
}

.phone-block::before {
  content: "";
  background-image: url('../public/Iphone.png');
  background-size: 100%;
  position: absolute;
  background-repeat: no-repeat;
  top: -1.8%;
  left: -5%;
  width: 109.6%;
  height: 106%;
}
.screen-block {
  border-radius: 8%;
}

.w-45{
  width: 46%;
}


.show {
  opacity: 100%;
  transition: 0.3s ease-in;
  position: relative;
  top: 0px;
}

.hide {
  opacity: 0%;
  position: relative;
  top: -20px;
  transition: 0.4s ease-out;
}

.hideNav{
  display: none;
}
.showNav{
  display: flex;
}

.headernav:hover{
  background-color:rgba(255, 255, 255, 0.6);
  filter: backdrop(0.1);
}

.agon-bg{
  background-color: #222222;
}
.birds-bg{
  background-color: #22242D;
}

/* .picture {
  border-width: 10px;
  border-color: white;
}

.pictureExpand{
  border-width: 24px;
  border-color: white;
} */